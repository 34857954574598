import React from 'react'
import Img from 'gatsby-image'
import Markdown from '../Markdown'
import { graphql } from 'gatsby'
import FiftyFifty from '../FiftyFifty'
import { SectionFiftyFiftyFragmentFragment } from '../../../types/graphql-types'

export const query = graphql`
  fragment SectionFiftyFiftyFragment on CMS_ComponentContentFiftyFifty {
    id
    animated: Animated
    wide: Wide
    Content
    content: ContentTransformed
    contentLeft: ContentLeft
    textAlignmentFF: TextAlignment
    imageMaxWidth: ImageMaxWidth
    imageMaxHeight: ImageMaxHeight
    imageAlt: ImageAlternativeText
    imageLink: ImageLink
    image: Image {
      id
      alternativeText
      url
      urlSharp {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  }
`

interface Props {
  data: SectionFiftyFiftyFragmentFragment
}

const SectionFiftyFifty: React.FC<Props> = (props: Props) => {
  const {
    animated,
    wide,
    image,
    imageMaxWidth,
    imageMaxHeight,
    imageLink,
    imageAlt,
    content,
    contentLeft,
    textAlignmentFF,
  } = props.data
  const alt = imageAlt || image?.alternativeText || ''
  const fluid = image?.urlSharp?.childImageSharp?.fluid
  return (
    <FiftyFifty
      animated={!!animated}
      wide={!!wide}
      contentRight={!contentLeft}
      image={
        fluid ? (
          <Img
            fluid={{ ...(fluid as any), aspectRatio: 1 }}
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: imageMaxHeight ? `${imageMaxHeight}px` : 'none',
              maxWidth: imageMaxWidth ? `${imageMaxWidth}px` : 'none',
            }}
            alt={alt}
            className="image-wrapper"
          />
        ) : null
      }
      imageLink={imageLink || undefined}
      textAlignment={textAlignmentFF || undefined}
    >
      <Markdown source={content} />
    </FiftyFifty>
  )
}

export default SectionFiftyFifty
