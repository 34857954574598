import React from 'react'
import Content from '../Content'
import Markdown from '../Markdown'
import { graphql } from 'gatsby'
import { SectionParagraphFragmentFragment } from '../../../types/graphql-types'

export const query = graphql`
  fragment SectionParagraphFragment on CMS_ComponentContentParagraph {
    id
    Content
    markdown: ContentTransformed
    textAlignment: TextAlignment
  }
`

interface Props {
  data: SectionParagraphFragmentFragment
}

const SectionParagraph: React.FC<Props> = (props: Props) => {
  const { markdown, textAlignment = 'Left' } = props.data
  const style: React.CSSProperties = {}
  if (textAlignment === 'Center') {
    style.textAlign = 'center'
  } else if (textAlignment === 'Right') {
    style.textAlign = 'right'
  }
  return (
    <Content style={style}>
      <Markdown source={markdown} />
    </Content>
  )
}

export default SectionParagraph
