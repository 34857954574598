import React from 'react'
import { useInView } from 'react-intersection-observer'
import { css } from 'styled-components'

interface Props {
  threshold?: number
}

type RevealedType = 'not' | 'slow' | 'fast'

const useScrollReveal = (props?: Props) => {
  const { threshold = 0.25 } = props || {}
  // const [visible, setVisible] = React.useState(false)
  const [revealed, setRevealed] = React.useState<RevealedType>('not')
  const [ref, inView, entry] = useInView({ threshold })
  React.useEffect(() => {
    if (inView) {
      // setVisible(inView)
      let rType: RevealedType = 'slow'
      if (entry && entry.intersectionRatio - threshold > 0.05) {
        // console.log(
        //   'Speed',
        //   entry.intersectionRatio - threshold > 0.05 ? 'Fast' : 'Slow'
        // )
        rType = 'fast'
      }
      setRevealed(rType)
      return
    }
    if (!entry) {
      return
    }
    // Only set visible false when scrolling out to the top
    const elIsAbove = entry.boundingClientRect.top < 0
    if (!elIsAbove) {
      // setVisible(false)
      setRevealed('not')
    }
  }, [inView, entry /* setVisible */, , setRevealed])
  return { ref, visible: revealed !== 'not', revealed }
}

export const revealStyling = css`
  transition: transform 1s ease-out, opacity 1s linear;
  transform: translateY(200px);
  opacity: 0;
  /* will-change: transform, opacity; */
  &.visible {
    transform: none;
    opacity: 1;
  }
  &.fast {
    transition: transform 0.5s ease-out, opacity 0.5s linear;
  }
`

export default useScrollReveal
