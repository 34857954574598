import React from "react";
import styled from "styled-components";
import Content from "../Content";
import ShareButton from "../ShareButton";
import PublishedOn from "../PublishedOn";

const Wrapper = styled(Content)`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${(props) =>
  props.theme.protraitBreakpoint}px) {
    flex-direction: column;
  }
`;

interface Props {
  url: string;
  title: string;
  published: string;
  hashtags: string;
}

const BlogPostFooter: React.FC<Props> = (props: Props) => {
  const { url, title, published, hashtags } = props;
  return <Wrapper>
    <ShareButton
      url={url}
      twitterText={title}
      twitterHashtags={hashtags}
      dropUp
    />
    <PublishedOn published={published} />
  </Wrapper>;
};

export default BlogPostFooter;
