import React from 'react'
import loadable from '@loadable/component'
import { CircleLoader } from '@aristech/components'
import ErrorBoundary from '../ErrorBoundary'

export interface Props {
  language: string
  children: string
}

const Highlight = loadable(() => import('./Highlight'), {
  fallback: <CircleLoader as="span" />,
})

const CodeSnippet: React.FC<Props> = (props: Props) => {
  const { language, children } = props
  return (
    <ErrorBoundary>
      <Highlight language={language}>{children}</Highlight>
    </ErrorBoundary>
  )
}

export default CodeSnippet
