import React from 'react'
import Content from '../Content'
import { graphql } from 'gatsby'
import CodeSnippet from '../CodeSnippet'
import { SectionCodeFragmentFragment } from '../../../types/graphql-types'

export const query = graphql`
  fragment SectionCodeFragment on CMS_ComponentContentCodeSnippet {
    id
    content: Content
    language
  }
`

interface Props {
  data: SectionCodeFragmentFragment
}

const SectionCode: React.FC<Props> = (props: Props) => {
  const { language, content } = props.data
  return (
    <Content>
      <CodeSnippet language={language}>{content}</CodeSnippet>
    </Content>
  )
}

export default SectionCode
