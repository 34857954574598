import React from 'react'
import Content from '../Content'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import MediaDescription from '../MediaDescription'
import { SectionImageFragmentFragment } from '../../../types/graphql-types'

export const query = graphql`
  fragment SectionImageFragment on CMS_ComponentContentImage {
    id
    alternativeText: AlternativeText
    caption: Caption
    image: Content {
      id
      alternativeText
      caption
      url
      urlSharp {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

interface Props {
  data: SectionImageFragmentFragment
}

const SectionImage: React.FC<Props> = (props: Props) => {
  const { alternativeText, caption, image } = props.data
  const fluid = image?.urlSharp?.childImageSharp?.fluid
  if (!image || !fluid) {
    return null
  }
  const alt = alternativeText || image.alternativeText || ''
  const description = caption || image.caption || ''
  return (
    <Content>
      <Img fluid={fluid as any} alt={alt} />
      <MediaDescription source={description} />
    </Content>
  )
}

export default SectionImage
