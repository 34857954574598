import React from 'react'
import { graphql } from 'gatsby'
import { SectionHeaderFragmentFragment } from '../../../types/graphql-types'
import BaseHeader from '../Header'
import styled from 'styled-components'
import Content from '../Content'

export const query = graphql`
  fragment SectionHeaderFragment on CMS_ComponentContentSectionHeader {
    id
    sectionTitle: Content
  }
`

const Header = styled(BaseHeader)`
  &:after {
    content: '';
    display: block;
    margin-top: 1.5rem;
    width: 100%;
    height: 2px;
    background: linear-gradient(
      90deg,
      rgba(238, 238, 238, 0.2987570028011205) 0%,
      rgba(238, 238, 238, 1) 10%,
      rgba(238, 238, 238, 1) 90%,
      rgba(238, 238, 238, 0) 100%
    );
  }
`

interface Props {
  data: SectionHeaderFragmentFragment
}

const SectionHeader: React.FC<Props> = (props: Props) => {
  const { sectionTitle } = props.data
  return (
    <Content>
      <Header as="h1">{sectionTitle}</Header>
    </Content>
  )
}

export default SectionHeader
