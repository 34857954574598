import React from 'react'
import Content from '../Content'
import { graphql } from 'gatsby'
import Audio from '../Audio'
import { AudioSource } from '../Audio/Props'
import MediaDescription from '../MediaDescription'
import { SectionAudioFragmentFragment } from '../../../types/graphql-types'
import useScrollReveal, { revealStyling } from '../../utils/useScrollReveal'
import styled from 'styled-components'

export const query = graphql`
  fragment SectionAudioFragment on CMS_ComponentContentAudio {
    id
    alternativeText: AlternativeText
    caption: Caption
    audio: Content {
      id
      alternativeText
      caption
      url
      urlSharp {
        publicURL
      }
    }
  }
`

interface Props {
  data: SectionAudioFragmentFragment
}

const Wrapper = styled(Content)`
  ${revealStyling}
`

const wavRe = /\.wav$/

const SectionAudio: React.FC<Props> = (props: Props) => {
  const { alternativeText, caption, audio } = props.data
  const { visible, ref } = useScrollReveal()
  const src = audio?.urlSharp?.publicURL
  if (!audio || !src) {
    return null
  }
  const alt = alternativeText || audio.alternativeText || ''
  const description = caption || audio.caption || ''

  const sources: [AudioSource] = [
    {
      src: src.replace(wavRe, '.m4a'),
      type: 'audio/mp4',
    },
  ]
  sources.push({
    src: src.replace(wavRe, '.ogg'),
    type: 'audio/ogg; codecs=vorbis',
  })
  sources.push({ src: src.replace(wavRe, '.flac'), type: 'audio/flac' })
  sources.push({ src, type: 'audio/wav' })
  return (
    <Wrapper className={visible ? 'visible' : ''} ref={ref}>
      <Audio src={sources} alt={alt} />
      <MediaDescription source={description} />
    </Wrapper>
  )
}

export default SectionAudio
