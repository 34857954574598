import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

interface Props {
  content1: React.ReactNode
  content2: React.ReactNode
  visible?: boolean
  setVisible?: (visible: boolean) => void
  last?: boolean
  lastVisible?: boolean
  hidePath?: boolean
}

export const Content = styled.div`
  -js-display: flex;
  display: flex;
  grid-area: content1;
  padding-bottom: 5em;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.25s linear, transform 0.25s ease-in-out;
  opacity: 0;
  transform-origin: bottom center;
  transform: scale(0.8);
  overflow: hidden;
  &:last-child {
    grid-area: content2;
  }
  /* IE 8+ */
  @media screen\\0 {
    grid-column: 1;
    -ms-grid-column: 1;
    &:last-child {
      grid-column: 3;
      -ms-grid-column: 3;
    }
    > * {
      width: 100%;
    }
  }
`
export const Bar = styled.div`
  grid-area: bar;
  position: relative;
  width: 50px;
  flex-shrink: 0;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transition: transform 0.25s ease-in-out, opacity 0.25s linear;
  }
  &:before {
    top: 0;
    width: 4px;
    margin-left: -1px;
    height: 100%;
    background: ${props => props.theme.primary};
    transform-origin: top center;
    transform: scaleY(0);
    opacity: 0;
  }
  &:after {
    top: 50%;
    width: 16px;
    height: 16px;
    margin-left: -11px;
    margin-top: -11px;
    border-radius: 50%;
    border: solid 4px ${props => props.theme.primary};
    background: white;
    transform-origin: center center;
    transform: scale(0);
    opacity: 0;
  }
  /* IE 8+ */
  @media screen\\0 {
    grid-column: 2;
    -ms-grid-column: 2;
  }
`
export const Wrapper = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 50px 1fr;
  grid-template-columns: 1fr 50px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-template-areas: 'content1 bar content2';
  align-items: stretch;
  &.visible {
    ${Bar} {
      &:before,
      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }
    ${Content} {
      opacity: 1;
      transform: scale(1);
    }
  }
  &.hide-path {
    grid-template-columns: 1fr 0px 1fr;
    ${Bar} {
      &:before,
      &:after {
        display: none;
      }
    }
  }
  &:nth-child(even) {
    grid-template-areas: 'content2 bar content1';
    ${Content}:last-child {
      text-align: right;
      align-items: flex-end;
      padding-right: 1.5rem;
      /* IE 8+ */
      @media screen\\0 {
        grid-column: 1;
        -ms-grid-column: 1;
      }
    }
    ${Content}:first-child {
      padding-left: 1.5rem;
      /* IE 8+ */
      @media screen\\0 {
        grid-column: 3;
        -ms-grid-column: 3;
      }
    }
  }
  &:nth-child(odd) {
    ${Content}:first-child {
      text-align: right;
      align-items: flex-end;
      padding-right: 1.5rem;
    }
    ${Content}:last-child {
      padding-left: 1.5rem;
    }
  }
  &:first-child ${Bar} {
    &:before {
      height: 50%;
      top: 50%;
    }
  }
  &:last-child ${Bar} {
    &:before {
      height: 50%;
      top: 0;
    }
  }
  &.last-visible:not(.last) ${Bar}:before {
    background: linear-gradient(
      180deg,
      ${props => props.theme.primary} 0%,
      ${props => props.theme.primary} 80%,
      transparent 100%
    );
  }
  @media only screen and (max-width: 850px) {
    max-width: 600px;
    margin: 0 auto;

    grid-template-columns: 50px 1fr;
    grid-template-areas: 'bar content1' 'bar content2' !important;
    ${Content}:first-child, ${Content}:last-child {
      text-align: left !important;
      align-items: flex-start !important;
      padding-right: 0;
      padding-left: 1.5rem;
    }
    ${Content} {
      padding-bottom: 1em;
    }

    &.hide-path {
      grid-template-columns: 0px 1fr;
    }
  }
`

const Item: React.FC<Props> = (props: Props) => {
  const {
    content1,
    content2,
    visible,
    setVisible,
    last,
    lastVisible,
    hidePath,
  } = props

  const [ref, inView, entry] = useInView({ threshold: 0.75 })
  React.useEffect(() => {
    if (!setVisible) {
      return
    }
    if (inView) {
      setVisible(inView)
      return
    }
    if (!entry) {
      return
    }
    // Only set visible false when scrolling out to the top
    const elIsAbove = entry.boundingClientRect.top < 0
    if (!elIsAbove) {
      setVisible(false)
    }
  }, [inView, entry /*, setVisible*/])

  if (!setVisible) {
    console.error(`Timeline Item is not wrapped in a Timeline wrapper`)
    return null
  }

  const classNames: Array<string> = []
  if (visible) {
    classNames.push('visible')
  }
  if (hidePath) {
    classNames.push('hide-path')
  }
  if (last) {
    classNames.push('last')
  }
  if (lastVisible) {
    classNames.push('last-visible')
  }
  return (
    <Wrapper className={classNames.join(' ')} ref={ref}>
      <Content>{content1}</Content>
      <Bar />
      <Content>{content2}</Content>
    </Wrapper>
  )
}

export default React.memo(Item)
