import React from 'react'
// import Content from '../Content'
import Markdown from '../Markdown'
import { graphql } from 'gatsby'
import { Container, Box } from '../Boxes'
import Header from '../Header'
import { SectionContentBoxesFragmentFragment } from '../../../types/graphql-types'
import styled from 'styled-components'
import useScrollReveal, { revealStyling } from '../../utils/useScrollReveal'
// import { Link as GatsbyLink } from 'gatsby-plugin-intl'
import BaseLink from '../Link'
import { Button, filterButtonProps } from '@aristech/components'

const Link = filterButtonProps(BaseLink)

export const query = graphql`
  fragment SectionContentBoxesFragment on CMS_ComponentContentBoxes {
    id
    title: Title
    animated: Animated
    boxes: Box {
      id
      iconName: IconName
      title: Title
      subTitle: SubTitle
      Text
      text: TextTransformed
      buttonLink: Link
      buttonTitle: LinkTitle
    }
  }
`

interface Props {
  data: SectionContentBoxesFragmentFragment
}

const Wrapper = styled.section`
  ${revealStyling}
`

const SectionContentBoxes: React.FC<Props> = (props: Props) => {
  const { title, boxes, animated } = props.data
  const { ref, visible, revealed } = useScrollReveal()
  const classNames: Array<string> = []
  if (!animated || visible) {
    classNames.push('visible')
  }
  if (revealed === 'fast') {
    classNames.push('fast')
  }
  if (!boxes) {
    return null
  }
  return (
    <Wrapper ref={ref} className={classNames.join(' ')}>
      <Header as="h1">{title}</Header>
      <Container>
        {boxes.map(
          ({
            id,
            iconName,
            title,
            subTitle,
            text,
            buttonLink,
            buttonTitle,
          }: any) => (
            <Box key={id}>
              <i className={`icon flaticon-${iconName}`} />
              <h2>{title}</h2>
              {subTitle && <h3>{subTitle}</h3>}
              <Markdown source={text} className="content" />
              {!!(buttonLink && buttonTitle) && (
                <Button
                  to={buttonLink}
                  as={Link}
                  color="primary"
                  className="link"
                >
                  {buttonTitle}
                </Button>
              )}
            </Box>
          )
        )}
      </Container>
    </Wrapper>
  )
}

export default SectionContentBoxes
