import React from 'react'
import styled from 'styled-components'
// import { /*InView,*/ useInView } from 'react-intersection-observer'
import Link from '../Link'
import useScrollReveal from '../../utils/useScrollReveal'

interface Props {
  children: React.ReactNode
  image: React.ReactNode
  imageLink?: string
  contentRight?: boolean
  textAlignment?: 'Left' | 'Center' | 'Right'
  animated: boolean
  wide: boolean
}

const Half = styled.div`
  -js-display: flex;
  display: flex;
  width: 50%;
  transition: transform 1s ease-out, opacity 1s linear;
  transform: translateY(200px);
  opacity: 0;
  /* will-change: transform, opacity; */
  align-items: center;
  &.content {
    transform: translateY(300px);
    h2 {
      font-weight: bold;
    }
    h3 {
      margin-top: 0;
      color: #ccc;
      font-size: ${props => props.theme.scale ** 2}rem;
    }
  }
  &.image .link {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    .image-wrapper {
      &:before,
      &:after {
        content: '';
        position: absolute;
        background: ${props => props.theme.primary};
        opacity: 0;
        transition: transform 0.2s linear, opacity 0.2s linear;
      }
      &:before {
        z-index: 1;
        width: 100%;
        height: 100%;
        transform-origin: top left;
        transform: scaleX(0);
      }
      &:after {
        font-family: Flaticon;
        content: '\\F129';
        font-size: 1.5rem;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        z-index: 2;
        top: 50%;
        left: 50%;
        margin-left: -25px;
        margin-top: -25px;
        transform-origin: center center;
        transform: scale(0);
      }
    }
    &:hover,
    &:focus {
      outline: none;
      .image-wrapper {
        &:before {
          opacity: 0.5;
          transform: scaleX(1);
        }
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
  &.image .image-wrapper {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
      0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
      0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  }
  &:nth-child(1) {
    justify-content: flex-end;
  }
  @media only screen and (max-width: 800px) {
    max-width: none;
    width: 100%;
    text-align: left;
    &.content {
      padding-top: 1.5rem;
    }
    &:nth-child(1) {
      justify-content: flex-start;
    }
  }
`
const Content = styled.div`
  max-width: 425px; // @see Components/Content
  padding-left: 3rem;
  padding-right: 1.5rem;
  box-sizing: border-box;
  .md > *:first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: 1050px) {
    padding: 0 1.5rem;
  }
  @media only screen and (max-width: 800px) {
    max-width: none;
  }
`
const Wrapper = styled.section`
  -js-display: flex;
  display: flex;
  &.visible {
    .content,
    .image {
      /* transition: transform 1s ease-out, opacity 1s linear; */
      transform: none;
      /* The following line forces hardware acceleration on mobile iOS to solve an issue with disappearing picture tags @see https://github.com/janpaepke/ScrollMagic/issues/467 */
      -webkit-transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    &.fast ${Half} {
      transition: transform 0.5s ease-out, opacity 0.5s linear;
    }
  }
  &.wide ${Content} {
    max-width: 600px;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    &.content-left {
      /* show picture always on top */
      flex-direction: column-reverse;
    }
    .image-wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const FiftyFifty: React.FC<Props> = (props: Props) => {
  const {
    children,
    contentRight = true,
    image: imageContent,
    textAlignment,
    imageLink,
    animated,
    wide,
  } = props

  const { ref, visible, revealed } = useScrollReveal()
  /*
  const [visible, setVisible] = React.useState(false)
  const [ref, inView, entry] = useInView({ threshold: 0.25 })
  React.useEffect(() => {
    if (inView) {
      setVisible(inView)
      return
    }
    if (!entry) {
      return
    }
    // Only set visible false when scrolling out to the top
    const elIsAbove = entry.boundingClientRect.top < 0
    if (!elIsAbove) {
      setVisible(false)
    }
  }, [inView, entry, setVisible])
  */

  const image = imageLink ? (
    <Link to={imageLink} className="link">
      {imageContent}
    </Link>
  ) : (
    imageContent
  )

  const style: React.CSSProperties = {}
  if (textAlignment === 'Center') {
    style.textAlign = 'center'
  } else if (textAlignment === 'Right') {
    style.textAlign = 'right'
  }

  const content = <Content>{children}</Content>
  const classNames: Array<string> = []
  if (wide) {
    classNames.push('wide')
  }
  if (!animated || visible) {
    classNames.push('visible')
  }
  if (revealed === 'fast') {
    classNames.push('fast')
  }
  if (contentRight) {
    classNames.push('content-right')
  } else {
    classNames.push('content-left')
  }
  return (
    <Wrapper className={classNames.join(' ')} style={style} ref={ref}>
      <Half className={!contentRight ? 'content' : 'image'}>
        {!contentRight ? content : image}
      </Half>
      <Half className={contentRight ? 'content' : 'image'}>
        {contentRight ? content : image}
      </Half>
    </Wrapper>
  )
}

export default FiftyFifty
