import React from 'react'
import Content from '../Content'
import Markdown from '../Markdown'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Timeline, { Item } from '../Timeline'
import Header from '../Header'
import { SectionTimelineFragmentFragment } from '../../../types/graphql-types'

export const query = graphql`
  fragment SectionTimelineFragment on CMS_ComponentContentTimeline {
    id
    title: Title
    subTitle: SubTitle
    hidePath: HidePath
    steps: Steps {
      id
      title: Title
      Content
      content: ContentTransformed
      image: Image {
        id
        urlSharp {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    }
  }
`

interface Props {
  data: SectionTimelineFragmentFragment
}

const SectionTimeline: React.FC<Props> = (props: Props) => {
  const { title, subTitle, steps, hidePath } = props.data
  if (!steps) {
    return null
  }
  return (
    <Content>
      {(title || subTitle) && (
        <Header style={{ paddingBottom: '5rem' }}>
          {title}
          {subTitle && <span className="subtitle">{subTitle}</span>}
        </Header>
      )}
      <Timeline>
        {steps.map(({ id, title, content, image }: any) => {
          const fluid = image?.urlSharp?.childImageSharp?.fluid
          return (
            <Item
              key={id}
              content1={
                <>
                  <h3>{title}</h3>
                  <Markdown source={content} />
                </>
              }
              content2={
                fluid && (
                  <Img
                    fluid={fluid}
                    style={{ width: '100%' }}
                    imgStyle={{
                      objectFit: 'contain',
                      objectPosition: '50% 50%',
                    }}
                  />
                )
              }
              hidePath={hidePath || undefined}
            />
          )
        })}
      </Timeline>
    </Content>
  )
}

export default SectionTimeline
