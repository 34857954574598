import React from 'react'
import styled from 'styled-components'
// import Item from './Item'
export { default as Item } from './Item'

interface Props {
  children: React.ReactNodeArray // ReturnType<typeof Item> | Array<ReturnType<typeof Item>>
}

const Wrapper = styled.div`
  -js-display: flex;
  display: flex;
  flex-direction: column;
`

const Timeline: React.FC<Props> = (props: Props) => {
  const { children } = props
  const childCount = React.Children.count(children)
  const [visibilities, setVisibilities] = React.useState<
    Array<boolean | undefined>
  >(new Array(childCount))

  const lastVisibleIdxReversed = visibilities
    .slice()
    .reverse()
    .findIndex(v => !!v)
  const lastVisibleIdx =
    lastVisibleIdxReversed === -1 ? 0 : childCount - 1 - lastVisibleIdxReversed
  return (
    <Wrapper>
      {React.Children.map(children, (child: any, idx) => {
        const last = idx === childCount - 1
        const lastVisible = idx === lastVisibleIdx
        const visible = idx === 0 || visibilities[idx]
        // const visible = visibilities[idx]
        const setVisible = (v: boolean) => {
          const newVisibilities = visibilities.slice()
          newVisibilities[idx] = v
          setVisibilities(newVisibilities)
        }
        return React.cloneElement(child, {
          ...child.props,
          visible,
          setVisible,
          last,
          lastVisible,
        })
      })}
    </Wrapper>
  )
}

export default Timeline
