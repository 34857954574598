/**
 * This component renders pages from the strapi cms
 */
import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import {
  PageQuery,
  SectionParagraphFragmentFragment,
  SectionContentBoxesFragmentFragment,
  SectionCodeFragmentFragment,
  SectionImageFragmentFragment,
  SectionFiftyFiftyFragmentFragment,
  SectionTimelineFragmentFragment,
  SectionAudioFragmentFragment,
  SectionHeaderFragmentFragment,
  SectionYouTubeFragmentFragment,
} from '../../types/graphql-types'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Content from '../components/Content'
import SEO from '../components/SEO'
import HeroImage from '../components/HeroImage'
import SectionParagraph from '../components/SectionParagraph'
import SectionContentBoxes from '../components/SectionContentBoxes'
import SectionCode from '../components/SectionCode'
import SectionImage from '../components/SectionImage'
import SectionFiftyFifty from '../components/SectionFiftyFifty'
import SectionTimeline from '../components/SectionTimeline'
import SectionAudio from '../components/SectionAudio'
import SectionHeader from '../components/SectionHeader'
import Header from '../components/Header'
import Contact from '../components/Contact'
import { Link as OriginalGatsbyLink } from 'gatsby'
import Schema from '../components/Schema'
import BlogPostFooter from '../components/BlogPostFooter'
import SectionYouTube from '../components/SectionYouTube'

interface Props {
  data: PageQuery
  pageContext: {
    slug: string
    pageType: 'Page' | 'BlogPost' | 'ResearchPost' | 'Product'
  }
}

const isPostType = (pageType: string) =>
  pageType === 'BlogPost' || pageType === 'ResearchPost'

const Page: React.FC<Props> = (props: Props) => {
  const { data, pageContext } = props
  const { pages, translations } = data.cms
  const [heroVisible, setHeroVisible] = React.useState(true)
  const intl = useIntl()

  const siteUrl = data.site!.siteMetadata!.siteUrl!
  const link: Array<any> = [
    {
      rel: 'canonical',
      href: `${siteUrl}/${intl.locale}/${pageContext.slug}`,
    },
  ]
  if (translations) {
    for (let i = 0; i < translations.length; ++i) {
      const translation = translations[i]
      if (!translation) continue
      const { Slug, Language } = translation
      if (!Language) continue
      const { locale } = Language
      link.push({
        rel: 'alternate',
        hrefLang: Language.locale,
        href: `${siteUrl}/${locale}/${Slug}`,
      })
      if (locale === 'de') {
        link.push({
          rel: 'alternate',
          hrefLang: 'x-default',
          href: `${siteUrl}/${locale}/${Slug}`,
        })
      }
    }
  }

  if (!pages || !pages.length) {
    return (
      <Layout
        menuData={(data as any).cms.mainMenu[0] as any}
        footerMenuData={(data as any).cms.footerMenus}
        languageSwitchData={(data as any).cms.languages}
      >
        <SEO
          title={intl.formatMessage({ id: 'notranslation.title' })}
          link={link}
          meta={[{ name: 'robots', content: 'noindex' }]}
        />
        <Content>
          <Header style={{ textAlign: 'left' }}>
            <FormattedMessage id="notranslation.header" />
          </Header>
          <p>
            <FormattedMessage id="notranslation.description" />
          </p>
          {data.cms.translations?.map(translation => {
            if (!translation) {
              return null
            }
            const { Language, Slug, Title } = translation
            if (!Slug || !Title || !Language) {
              return null
            }
            const { locale, Title: LanguageTitle } = Language
            const to = `/${locale}/${Slug}`
            return (
              <OriginalGatsbyLink to={to} key={to}>
                {LanguageTitle} - {Title}
              </OriginalGatsbyLink>
            )
          })}
        </Content>
      </Layout>
    )
  }

  const page = pages[0]!

  // const script: Array<any> = []
  const { Schema: schema } = page
  // if (schema) {
  //   script.push({
  //     type: 'application/ld+json',
  //     innerHTML: JSON.stringify(schema),
  //   })
  // }

  const { Title, MetaDescription, HeroImage: HImage, Sections, DarkMenu } = page
  const seoData: { description?: string } = {}
  const meta: Array<any> = []
  if (MetaDescription) {
    seoData.description = MetaDescription
  }
  if (page.HideFromSearchEngines === true) {
    meta.push({ name: 'robots', content: 'noindex' })
  }
  const heroImage = HImage?.urlSharp?.childImageSharp?.fluid
  return (
    <Layout
      menuData={(data as any).cms.mainMenu[0] as any}
      footerMenuData={(data as any).cms.footerMenus}
      languageSwitchData={(data as any).cms.languages}
      transparentMenubar={!!heroImage}
      heroImageMenubar={heroImage}
      heroIsVisible={heroVisible}
      darkMenu={DarkMenu || undefined}
    >
      <SEO
        title={Title}
        {...seoData}
        link={link}
        previewImage={
          page.HeroImage?.urlSharp?.childImageSharp?.fluid || undefined
        } /* script={script} */
        meta={meta}
      />
      {!!schema && <Schema schema={schema} />}
      {heroImage ? (
        <HeroImage
          image={heroImage}
          title={Title}
          onVisibilityChange={visible => setHeroVisible(visible)}
        />
      ) : (
        <Content
          style={{ paddingBottom: 0, marginBottom: 0, marginTop: '3rem' }}
        >
          <Header as="h1">{Title}</Header>
        </Content>
      )}
      {Sections.map((section: any) => {
        if (!section) {
          return null
        }
        const { __typename, id } = section
        if (__typename === 'CMS_ComponentContentSectionHeader') {
          return (
            <SectionHeader
              key={id}
              data={section as SectionHeaderFragmentFragment}
            />
          )
        }
        if (__typename === 'CMS_ComponentContentParagraph') {
          return (
            <SectionParagraph
              key={id}
              data={section as SectionParagraphFragmentFragment}
            />
          )
        }
        if (__typename === 'CMS_ComponentContentBoxes') {
          return (
            <SectionContentBoxes
              key={id}
              data={section as SectionContentBoxesFragmentFragment}
            />
          )
        }
        if (__typename === 'CMS_ComponentContentFiftyFifty') {
          return (
            <SectionFiftyFifty
              key={id}
              data={section as SectionFiftyFiftyFragmentFragment}
            />
          )
        }
        if (__typename === 'CMS_ComponentContentImage') {
          return (
            <SectionImage
              key={id}
              data={section as SectionImageFragmentFragment}
            />
          )
        }
        if (__typename === 'CMS_ComponentContentAudio') {
          return (
            <SectionAudio
              key={id}
              data={section as SectionAudioFragmentFragment}
            />
          )
        }
        if (__typename === 'CMS_ComponentContentCodeSnippet') {
          return (
            <SectionCode
              key={id}
              data={section as SectionCodeFragmentFragment}
            />
          )
        }
        if (__typename === 'CMS_ComponentContentTimeline') {
          return (
            <SectionTimeline
              key={id}
              data={section as SectionTimelineFragmentFragment}
            />
          )
        }
        if (__typename === 'CMS_ComponentContentYouTube') {
          return (
            <SectionYouTube
              key={id}
              data={section as SectionYouTubeFragmentFragment}
            />
          )
        }
        return null
      })}

      {!isPostType(pageContext.pageType) && page.HideContactForm !== true && (
        <Contact />
      )}
      {isPostType(pageContext.pageType) && (
        <BlogPostFooter
          url={page.Slug}
          title={Title}
          published={page.Published}
          hashtags={page.Hashtags || ''}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query Page($language: String!, $slug: String!, $pageType: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    cms {
      mainMenu: menus(
        where: { Position: "Main", Language: { locale: $language } }
      ) {
        ...MainMenuFragment
      }
      footerMenus: menus(
        where: { Position_ne: "Main", Language: { locale: $language } }
      ) {
        ...FooterMenusFragment
      }

      languages {
        ...LanguageSwitchFragment
      }

      pages(
        where: { Language: { locale: $language }, Slug: $slug, Type: $pageType }
      ) {
        id
        Title
        MetaDescription
        Schema
        DarkMenu
        Slug
        Hashtags
        Published
        HideContactForm
        HideFromSearchEngines
        HeroImage {
          id
          url
          urlSharp {
            childImageSharp {
              fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        Sections {
          __typename
          ... on CMS_ComponentContentSectionHeader {
            ...SectionHeaderFragment
          }
          ... on CMS_ComponentContentCodeSnippet {
            ...SectionCodeFragment
          }
          ... on CMS_ComponentContentImage {
            ...SectionImageFragment
          }
          ... on CMS_ComponentContentParagraph {
            ...SectionParagraphFragment
          }
          ... on CMS_ComponentContentFiftyFifty {
            ...SectionFiftyFiftyFragment
          }
          ... on CMS_ComponentContentBoxes {
            ...SectionContentBoxesFragment
          }
          ... on CMS_ComponentContentTimeline {
            ...SectionTimelineFragment
          }
          ... on CMS_ComponentContentAudio {
            ...SectionAudioFragment
          }
          ... on CMS_ComponentContentYouTube {
            ...SectionYouTubeFragment
          }
        }
      }

      translations: pages(where: { Slug: $slug, Type: $pageType }) {
        Slug
        Title
        Language {
          locale
          Title
        }
      }
    }
  }
`

export default Page
