import React from 'react'
import Helmet from 'react-helmet'

interface Props {
  schema: Object
}

const Schema: React.FC<Props> = (props: Props) => {
  const { schema } = props
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default Schema
