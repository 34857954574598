import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import useScrollReveal, { revealStyling } from '../../utils/useScrollReveal'
import Content from '../Content'
import MediaDescription from '../MediaDescription'
import { SectionYouTubeFragmentFragment } from '../../../types/graphql-types'

export const query = graphql`
  fragment SectionYouTubeFragment on CMS_ComponentContentYouTube {
    id
    embedingCode: EmbedingCode
    caption: Caption
    animated: Animated
  }
`

interface Props {
  data: SectionYouTubeFragmentFragment
}

const embedingCodeVerification = /^<iframe [^>]+>[^<]*<\/iframe>$/

const getAspectRatioPercentage = (embedingCode: string) => {
  const widthRe = /^<iframe.*width="([0-9]+)".*/
  const heightRe = /^<iframe.*height="([0-9]+)".*/
  const width = parseInt(embedingCode.replace(widthRe, '$1'), 10)
  const height = parseInt(embedingCode.replace(heightRe, '$1'))
  if (isNaN(width) || isNaN(height) || width <= 0 || height <= 0) {
    return null
  }
  return height / width
}

const Wrapper = styled(Content)`
  ${revealStyling}
`
const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`

const SectionYouTube: React.FC<Props> = (props: Props) => {
  const { embedingCode, caption, animated } = props.data
  const { visible, ref } = useScrollReveal()
  const isValid = embedingCodeVerification.test(embedingCode)
  if (!isValid) {
    console.error('Found invalid youtube embeding code', embedingCode)
    return null
  }
  const aspectRatio = getAspectRatioPercentage(embedingCode)
  if (aspectRatio === null) {
    console.error(
      'Could not determine aspect ratio for youtube embeding code',
      embedingCode
    )
    return null
  }
  return (
    <Wrapper className={!animated || visible ? 'visible' : ''} ref={ref}>
      <VideoWrapper
        style={{ paddingBottom: `${Math.floor(aspectRatio * 100)}%` }}
        dangerouslySetInnerHTML={{ __html: embedingCode }}
      />
      {caption && <MediaDescription source={caption} />}
    </Wrapper>
  )
}

export default SectionYouTube
